/*
 * Why Choose
 * -----------------------------------------------
*/

.img-icon-box {
	position: relative;
	overflow: hidden;
	-webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.img-icon-box:before {
	position: absolute;
	background: rgba(255, 100, 100, 0.8);
	content: '';
	height: 100%;
	width: 100%;
	display: block;
}
.img-icon-box .img-icon-details {
	position: relative;
	min-height: 260px;
}
.img-icon-box .img-icon-details .flaticon-weights19,
.img-icon-box .img-icon-details .flaticon-gym-5,
.img-icon-box .img-icon-details .flaticon-gym-2,
.img-icon-box .img-icon-details .fitness-details,
.img-icon-box .img-icon-details .flaticon-weights19,
.img-icon-box .img-icon-details .flaticon-bodyparts70,
.img-icon-box .img-icon-details .flaticon-gym6,
.img-icon-box .img-icon-details .flaticon-gym5,
.img-icon-box .img-icon-details .flaticon-gym-6  {
	color: #fff;
}
.img-icon-box .img-icon-content  {
	opacity: 0;
	-webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.img-icon-box:hover .img-icon-content  {
	opacity: 1;
}
.img-icon-box .fitness-details,
.img-icon-box .flaticon-weights19,
.img-icon-box .flaticon-bodyparts70,
.img-icon-box .flaticon-gym6,
.img-icon-box .flaticon-gym5,
.img-icon-box .flaticon-gym-2,
.img-icon-box .flaticon-gym-5,
.img-icon-box .flaticon-gym-6,
.img-icon-box h3 {
	-webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	-o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.img-icon-box:hover .flaticon-gym-2,
.img-icon-box:hover .flaticon-weights19,
.img-icon-box:hover .flaticon-bodyparts70,
.img-icon-box:hover .flaticon-gym6,
.img-icon-box:hover .flaticon-gym5,
.img-icon-box:hover .flaticon-gym-5, 
.img-icon-box:hover .flaticon-gym-6  {
	font-size: 0;
}