/*
 * Shortcode: Clients.less
 * -----------------------------------------------
*/
.clients-logo {
	.item {
		border-color: transparent;
		box-shadow: 0 0 0 10px fade(@white-base, 100%);
		margin-bottom: 30px;
		padding: 15px;
		.transition(all .3s ease 0s);
	}

	img {
		max-height: 90px;
		width: auto;
		display: inline;
		opacity: 0.9;

		&:hover {
			opacity: 1;
		}
	}
	
}

.clients-logo {
	&.style2 {
		.item {
			box-shadow: none;

			img {
				max-height: 120px;
			}
		}
		
	}
}

.client-img {
	opacity: 0.6;
	text-align: center;
	.transition(all .3s ease 0s);

	&:hover {
		opacity: 1;
	}

	img {
		max-height: 90px;
	}
}

.clients-logo {
	&.carousel {
		text-align: center;
		.item {
			box-shadow: none;
			margin: 0 10px;
		}
	}
}
.clients .client {
	text-align: center;
}
.clients .client img {
	opacity: 0.7;
}
.clients .client:hover img {
	opacity: 1;
	.transition(all 300ms ease 0s);
}

