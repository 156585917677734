@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        EquestrianPress
Version:        1.0
Last change:    08.23.2016
Primary use:    Equestrian & Horse Riding Training HTML Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Table of contents
@import "less-equestrianpress/table-of-content.less";

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";


//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-gardening.css";
@import "../css/utility-classes.css";
@import "../css/flaticon-set-horseman.css";
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,700,300,100);
/* @import url(https://fonts.googleapis.com/css?family=Droid+Sans:400,700); */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900,700italic,400italic);

 

 
// Initialize Variables
@import "less-equestrianpress/variables.less";
@import "less-equestrianpress/mixins.less";
// Typography
@import "less-equestrianpress/typography.less";

// Common Styles
@import "less-equestrianpress/common.less";
@import "less-equestrianpress/extra.less";
@import "less-equestrianpress/overlay.less";

// Header
@import "less-equestrianpress/header.less";

// Nav
@import "less-equestrianpress/nav.less";

// Content Blocks
@import "less-equestrianpress/horse-gallery.less";
@import "less-equestrianpress/topbar.less";
@import "less-equestrianpress/inner-header-title.less";
@import "less-equestrianpress/vertical-nav.less";
@import "less-equestrianpress/menu-full-page.less";
@import "less-equestrianpress/boxed-layout.less";
@import "less-equestrianpress/form.less";
@import "less-equestrianpress/side-push-panel.less";
@import "less-equestrianpress/box-hover-effect.less";
@import "less-equestrianpress/gallery-isotope.less";
@import "less-equestrianpress/sliders.less";
@import "less-equestrianpress/home.less";
@import "less-equestrianpress/about.less";
@import "less-equestrianpress/contact.less";
@import "less-equestrianpress/event.less";
@import "less-equestrianpress/responsive.less";
@import "less-equestrianpress/services.less";
@import "less-equestrianpress/shop.less";
@import "less-equestrianpress/classes-details.less";
@import "less-equestrianpress/blog.less";
@import "less-equestrianpress/class-time.less";
@import "less-equestrianpress/funfact.less";
@import "less-equestrianpress/trainer.less";
@import "less-equestrianpress/traning-class.less";
@import "less-equestrianpress/calculator.less";
@import "less-equestrianpress/why-choose.less";
@import "less-equestrianpress/traning-class.less";



// Shortcodes
@import "less-equestrianpress/shortcodes.less";


// Widgets
@import "less-equestrianpress/widgets.less";


// Footer
@import "less-equestrianpress/footer.less";